import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "./Button";
import styled from "styled-components";
import { Link } from "gatsby";

export type HubBoxProps = {
  image: any;
  url: string;
  productName: string;
  imageAlt: string;
  text?: string;
  cta?: string;
};

const Title = styled.p`
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #38b9e2 0%, #c7db4e 100%);
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
  margin: 0;
`;

const HubBox: React.FC<HubBoxProps & JSX.IntrinsicElements["div"]> = ({
  image,
  imageAlt,
  url,
  productName,
  text,
  cta,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`flex flex-col md:flex-row bg-accuRankerPurple-400 items-start border-2 border-gray-700 rounded-3xl mx-auto ${props.className}`}
    >
      <div className="p-8 mt-2">
        {!image.childImageSharp && image.extension === "svg" ? (
          <img src={image.publicURL} alt={imageAlt} className="w-28 mr-3" />
        ) : (
          <GatsbyImage
            key={image.id}
            image={image.childImageSharp.gatsbyImageData}
            alt={imageAlt}
            className="w-28 mr-3"
          />
        )}
      </div>
      <div className="flex flex-col p-8 justify-between w-full space-y-8 h-full">
        <Title>{productName}</Title>
        <p className="text-gray-400">{text}</p>
        <Link
          to={url}
          className="max-w-max rounded-md bg-gray-500 hover:bg-gray-700 font-medium text-white px-4 py-3 transition-color duration-300"
        >
          {cta}
        </Link>
      </div>
    </div>
  );
};
export default HubBox;
