import * as React from "react";

function HubStraightLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={48} viewBox="0 0 48 986" preserveAspectRatio="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path fill="#C7DB4E" d="M43 30.943h4v903.333h-4z" />
        <path fill="#82CA69" d="M29 30.943h4v903.333h-4z" />
        <path fill="#38E2A5" d="M15 30.943h4v903.333h-4z" />
        <path fill="#38B9E2" d="M1 30.943h4v903.333H1z" />
      </g>
      <path fill="#38B9E2" d="M1 463.674h4v66.503H1z" />
      <path fill="#38E2A5" d="M15 463.674h4v66.503h-4z" />
      <path fill="#82CA69" d="M29 463.674h4v66.503h-4z" />
      <path fill="#C7DB4E" d="M43 463.674h4v66.503h-4z" />
      <path fill="#38B9E2" d="M1 556.502h4v66.503H1z" />
      <path fill="#38E2A5" d="M15 556.502h4v66.503h-4z" />
      <path fill="#82CA69" d="M29 556.502h4v66.503h-4z" />
      <path fill="#C7DB4E" d="M43 556.502h4v66.503h-4z" />
      <path fill="#38B9E2" d="M1 806.811h4v66.503H1z" />
      <path fill="#38E2A5" d="M15 806.811h4v66.503h-4z" />
      <path fill="#82CA69" d="M29 806.811h4v66.503h-4z" />
      <path fill="#C7DB4E" d="M43 806.811h4v66.503h-4z" />
      <path fill="#38B9E2" d="M1 226.758h4v66.503H1z" />
      <path fill="#38E2A5" d="M15 226.758h4v66.503h-4z" />
      <path fill="#82CA69" d="M29 226.758h4v66.503h-4z" />
      <path fill="#C7DB4E" d="M43 226.758h4v66.503h-4z" />
      <path fill="#38B9E2" d="M1 291.412h4v66.503H1z" />
      <path fill="#38E2A5" d="M15 291.412h4v66.503h-4z" />
      <path fill="#82CA69" d="M29 291.412h4v66.503h-4z" />
      <path fill="#C7DB4E" d="M43 291.412h4v66.503h-4z" />
      <path fill="#38B9E2" d="M1 32.79h4V0H1z" />
      <path fill="#38E2A5" d="M15 32.79h4V0h-4z" />
      <path fill="#82CA69" d="M29 32.79h4V0h-4z" />
      <path fill="#C7DB4E" d="M43 32.79h4V0h-4z" />
      <path
        transform="matrix(0 1 1 0 1 0)"
        fill="url(#prefix__paint0_linear)"
        d="M0 0h32.79v46H0z"
      />
      <path
        d="M19 938.567v-4.292h-4v3.794c0 .85.339 1.693 1 2.487l3 3.603c.661.793 1 1.636 1 2.487v5.179h2v-5.677c0-.524-.129-1.047-.384-1.558l-2.232-4.466a3.462 3.462 0 01-.384-1.557z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M19 938.567v-4.292h-4v3.794c0 .85.339 1.693 1 2.487l3 3.603c.661.793 1 1.636 1 2.487v5.179h2v-5.677c0-.524-.129-1.047-.384-1.558l-2.232-4.466a3.462 3.462 0 01-.384-1.557z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        d="M29 938.567v-4.292h4v3.794c0 .85-.339 1.693-1 2.487l-3 3.603c-.661.793-1 1.636-1 2.487v5.179h-2v-5.677c0-.524.129-1.047.384-1.558l2.232-4.466a3.462 3.462 0 00.384-1.557z"
        fill="url(#prefix__paint3_linear)"
      />
      <path
        d="M29 938.567v-4.292h4v3.794c0 .85-.339 1.693-1 2.487l-3 3.603c-.661.793-1 1.636-1 2.487v5.179h-2v-5.677c0-.524.129-1.047.384-1.558l2.232-4.466a3.462 3.462 0 00.384-1.557z"
        fill="url(#prefix__paint4_linear)"
      />
      <path
        d="M43 936.818v-2.543h4v2.211c0 1.837-1.58 3.599-4.393 4.898l-4.214 1.946C35.58 944.629 34 946.391 34 948.229v3.596h-2v-3.928c0-1.639 1.258-3.224 3.55-4.475l3.9-2.129c2.292-1.251 3.55-2.837 3.55-4.475z"
        fill="url(#prefix__paint5_linear)"
      />
      <path
        d="M43 936.818v-2.543h4v2.211c0 1.837-1.58 3.599-4.393 4.898l-4.214 1.946C35.58 944.629 34 946.391 34 948.229v3.596h-2v-3.928c0-1.639 1.258-3.224 3.55-4.475l3.9-2.129c2.292-1.251 3.55-2.837 3.55-4.475z"
        fill="url(#prefix__paint6_linear)"
      />
      <path
        d="M5 936.818v-2.543H1v2.211c0 1.837 1.58 3.599 4.393 4.898l4.214 1.946c2.813 1.299 4.393 3.061 4.393 4.899v3.596h2v-3.928c0-1.639-1.258-3.224-3.55-4.475l-3.9-2.129C6.257 940.042 5 938.456 5 936.818z"
        fill="url(#prefix__paint7_linear)"
      />
      <path
        d="M5 936.818v-2.543H1v2.211c0 1.837 1.58 3.599 4.393 4.898l4.214 1.946c2.813 1.299 4.393 3.061 4.393 4.899v3.596h2v-3.928c0-1.639-1.258-3.224-3.55-4.475l-3.9-2.129C6.257 940.042 5 938.456 5 936.818z"
        fill="url(#prefix__paint8_linear)"
      />
      <path d="M16 951.824h-2v34.175h2v-34.175z" fill="#0F2D4C" />
      <path d="M22 951.824h-2v34.175h2v-34.175z" fill="#0F3540" />
      <path d="M28 951.824h-2v34.175h2v-34.175z" fill="#1E3034" />
      <path d="M34 951.824h-2v34.175h2v-34.175z" fill="#2C342F" />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={0}
          y1={26.661}
          x2={24.994}
          y2={26.661}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#050A27" />
          <stop offset={1} stopColor="#050A27" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={18.5}
          y1={951.825}
          x2={18.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0F6A84" />
          <stop offset={1} stopColor="#38E2A5" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={18.5}
          y1={951.825}
          x2={18.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.5} />
          <stop offset={0.661} stopOpacity={0.5} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={29.5}
          y1={951.825}
          x2={29.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C6169" />
          <stop offset={1} stopColor="#82CA69" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={29.5}
          y1={951.825}
          x2={29.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.5} />
          <stop offset={0.578} stopOpacity={0.5} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={39.5}
          y1={951.825}
          x2={39.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56685D" />
          <stop offset={1} stopColor="#C7DB4E" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={39.5}
          y1={951.825}
          x2={39.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.5} />
          <stop offset={0.667} stopOpacity={0.5} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={8.5}
          y1={951.825}
          x2={8.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B5898" />
          <stop offset={1} stopColor="#38B9E2" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={8.5}
          y1={951.825}
          x2={8.5}
          y2={934.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.5} />
          <stop offset={0.698} stopOpacity={0.5} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(0 30.943)"
            d="M0 0h48v903.333H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HubStraightLine;
