import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import { BookDemoBig } from "../ui-components/BookDemos";
import { StaticImage } from "gatsby-plugin-image";
import HubBox from "../ui-components/HubBox";
import ClientsSection from "../components/ClientsSection";
import DemoPortrait from "../images/DemoPortrait.png";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import HubStraightLine from "../ui-components/SVGs/HubStraigthLine";
import { DeepNonNullable } from "ts-essentials";
import { HubDataQuery } from "../../graphqlTypes";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";

const HubPage = () => {
  const data = useStaticQuery<DeepNonNullable<HubDataQuery>>(graphql`
    query HubData {
      strapiHub {
        pageTitle
        childStrapiHubTitle {
          childMdx {
            body
          }
        }
        childStrapiHubSubtitle {
          childMdx {
            body
          }
        }
        teaser
        boxes {
          id
          title
          description
          url
          cta
          icon {
            alternativeText
            image: localFile {
              id
              publicURL
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageTitle,
    childStrapiHubTitle: title,
    childStrapiHubSubtitle: subtitle,
    teaser,
    boxes,
  } = data.strapiHub;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block mt-10">
        <div className="flex flex-col items-start md:items-center text-center md:w-full md:p-20 md:pb-10 bg-accuRankerPurple-900">
          <div
            id="title"
            className="text-left md:text-center text-white text-2xl md:text-5xl font-medium"
          >
            <MDXRenderer>{mdxBody(title)}</MDXRenderer>
          </div>
          <div id="subtitle">
            <p className="text-left md:text-center text-white mt-10 text-xl">
              <MDXRenderer>{mdxBody(subtitle)}</MDXRenderer>
            </p>
          </div>
          <div className="mt-10">
            <p className="text-gray-400">{teaser}</p>
          </div>
        </div>
      </div>
      {/* Hubs Waterfall */}
      <div className="relative div-block mt-28 md:mt-0 md:px-0 md:pt-16 md:pb-20">
        {/* Long Colored Line - Centered */}
        <div
          className="hidden lg:block absolute mx-auto left-0 right-0 text-center h-full"
          style={{ zIndex: -2 }}
        >
          <HubStraightLine
            height="calc(100% + 140px)"
            width="48px"
            className="mx-auto"
          />
        </div>

        {/* Unique Feature */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 md:gap-y-24">
          {boxes.map((box, index: number) => (
            <div
              key={index}
              className={classNames(
                "relative col-span-2 mx-10 lg:mx-0 lg:col-span-1"
              )}
            >
              <div className="relative h-full">
                <HubBox
                  url={box.url}
                  productName={box.title}
                  text={box.description}
                  cta={box.cta}
                  image={box.icon.image}
                  imageAlt={box.icon.alternativeText}
                  style={{
                    width: "calc(100% - 41px)",
                  }}
                  className={classNames("h-full", {
                    "lg:ml-0": index % 2 === 0,
                    "lg:mr-0": index % 2 === 1,
                  })}
                />
                <div
                  className={classNames("hidden lg:block absolute", {
                    "order-last": index % 2 === 0,
                    "order-first": index % 2 === 1,
                  })}
                  style={{
                    top: index % 2 === 1 ? "8%" : "70%",
                    left: index % 2 === 0 ? "calc(100% - 53px)" : "-23px",
                    width: "300px",
                    minWidth: "300px",
                    zIndex: -1,
                  }}
                >
                  {index % 2 === 0 ? (
                    <StaticImage
                      src="../images/Curves/HubCurvesSmallLeft.svg"
                      alt="curve-hook"
                      imgStyle={{
                        width: "76px",
                        height: "100%",
                      }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/Curves/HubCurvesSmallRight.svg"
                      alt="curve-hook"
                      imgStyle={{
                        width: "76px",
                        height: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Clients Section */}
      <div className="div-block flex flex-col justify-center items-center mt-48 relative">
        <div className="max-w-full mt-10 md:mt-0 custom-faded-curves">
          <ClientsSection />
        </div>
      </div>
      {/* CTA Section */}
      <div className="max-w-full flex flex-col justify-center items-center mt-0 my-20 mx-10 relative">
        <StaticImage
          src="../images/Curves/Narrow2Wide.svg"
          alt="narrow-to-wide-curve"
          imgClassName="hidden lg:block"
        />
        <BookDemoBig
          cta="Book Demo With Our Manager"
          image={DemoPortrait}
          jobTitle="Client Service Manager"
          name="Victor Pan"
          text="Get a free demo by one of our customer success managers"
        />
        <StaticImage
          src="../images/Curves/FinishingCurves.svg"
          alt="narrow-to-wide-curve"
          imgClassName="hidden lg:block"
        />
      </div>
      <Footer />
    </Layout>
  );
};

export default HubPage;
